/* .cke_dialog_background_cover,
.cke_reset_all.cke_dialog_container {
  display: none !important;
} */

.cke_dialog_open,
.js-focus-visible {
  overflow: auto !important;
}

.cke_button__image {
  display: none !important;
}
